var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'button',
    _vm.kindClass,
    _vm.sizeClass,
    { 'pl-2': this.$slots.left },
    { 'pr-2': this.$slots.right },
    { 'button-icon': _vm.iconOnly },
    { 'w-full': _vm.stretch },
    { 'disabled': _vm.disabled }
  ],on:{"click":_vm.handleClick}},[_vm._t("left"),_vm._t("default"),_vm._t("right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }