<template>
  <button
    @click="handleClick"
    :class="[
      'button',
      kindClass,
      sizeClass,
      { 'pl-2': this.$slots.left },
      { 'pr-2': this.$slots.right },
      { 'button-icon': iconOnly },
      { 'w-full': stretch },
      { 'disabled': disabled }
    ]"
  >
    <slot name="left"/>
    <slot />
    <slot name="right"/>
  </button>
</template>

<script>
import validatorUtil from '../../util/util.validator';

const PxButtonKindEnum = {
  basic: '',
  primary: 'button-primary',
  'primary-subtle': 'button-primary-subtle',
  'primary-plain': 'button-link-subtle',
  'danger-subtle': 'button-danger-subtle',
};

const PxButtonSizeEnum = {
  small: 'button-sm',
  medium: '',
};

export default {
  name: 'px-button',
  props: {
    kind: {
      type: String,
      default: 'basic',
      validator: validatorUtil.isOfType(PxButtonKindEnum),
    },
    size: {
      type: String,
      default: 'medium',
      validator: validatorUtil.isOfType(PxButtonSizeEnum),
    },
    href: String,
    iconOnly: Boolean,
    stretch: Boolean,
    disabled: Boolean,
  },
  computed: {
    kindClass() {
      return PxButtonKindEnum[this.kind];
    },
    sizeClass() {
      return PxButtonSizeEnum[this.size];
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        if (this.href) {
          this.$router.push(this.href);
        }
        this.$emit('click');
      }
    },
  },
};
</script>
