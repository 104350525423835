<template>
    <div>
      <header class="w-screen bg-primary-800 flex justify-center py-10">
          <a href="#" class="space-y-2">
            <img
              src="https://cdn.parcelex.com.br/assets/v3/logos/parcelex-centrax-white.png"
              class="h-8"
              alt="Logo Parcelex"
            />
          </a>
      </header>
      <div class="flex mx-auto items-center flex-col mt-10 gap-4 max-w-sm">
        <PxIcon
          class="mb-3 w-10 bg-yellow-100 p-2 rounded-xl"
          :name="exclamationIcon"
        />
        <h1 class="text-xl font-bold text-center">Cadastro não aprovado</h1>
          <p class="text-center">
          Não foi possível aprovar o cadastro da empresa
            <strong>{{retailerName}}</strong> no Parcelex para Negócios.
          </p>

          <PxButton class="px-5" kind="primary" @click="logoff">Sair</PxButton>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import PxIcon from '../../../components/custom/PxIcon.vue';
import PxButton from '../../../components/custom/PxButton.vue';

export default {
  name: 'ReprovedRegistration',
  components: {
    PxIcon,
    PxButton,
  },
  data: () => ({
    currentOnboardingStep: 2.5,
    exclamationIcon: 'exclamation-triangle-icon.svg',
  }),
  computed: {
    retailerName() {
      return this.$store.state.onboarding.retailer.name;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('onboarding', ['clearInitialData']),
    logoff() {
      this.clearInitialData();
      this.logout();
    },
  },
};

</script>
