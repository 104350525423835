<template>
  <img @click="onClick" :src="src"/>
</template>

<script>
export default {
  name: 'px-icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      return `https://cdn.parcelex.com.br/assets/v3/icons/${this.name}`;
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
