const validator = require('email-validator');

function isOfType(type) {
  let typeArray = type;
  if (!Array.isArray(type)) {
    typeArray = Object.keys(type);
  }
  return typeArray.indexOf(type) !== -1;
}

function isValidEmail(email) {
  if (!email) return false;

  return validator.validate(email);
}

export default {
  isOfType,
  isValidEmail,
};
